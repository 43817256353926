.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 3rem 2rem 2rem 4rem;
    // padding: 7rem 2rem 2rem 4rem;
    transition: margin-left $transitionDuration;
}
.layout-top-container {
    display: flex;
    justify-content: center;
    padding: 5rem 0rem 0rem 0rem;
    transition: margin-left 0.2s;
    align-items: center;
    flex-direction: column;
    background-color: #000;
    margin-bottom: -65px;
}

.layout-main {
    flex: 1 1 auto;
}
