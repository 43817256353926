// .layout-sidebar {
//     position: fixed;
//     border-top-right-radius: 50px;
//     width: 250px;
//     height: calc(100vh);
//     z-index: 999;
//     overflow-y: auto;
//     user-select: none;
//     top: 0rem;
//     left: 0rem;
//     transition: transform 0.2s, left 0.2s;
//     background-color: #1E1E1E;
//     padding-top: 1.5rem;
//     box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08)
// }


// .layout-menu {
//     list-style-type: none;
//     margin: 0;
//     padding: 0;

//     li {
//         &.layout-menuitem-category {
//             margin-top: .75rem;

//             &:first-child {
//                 margin-top: 0;
//             }
//         }

//         .layout-menuitem-root-text {
//             text-transform: uppercase;
//             color:#7C7C7C;
//             font-weight: 600;
//             margin-bottom: .5rem;
//             font-size: .875rem;
//         }

//         a {
//             cursor: pointer;
//             text-decoration: none;
//             display: flex;
//             align-items: center;
//             color:#A1A5B7;
//             padding: .75rem 1rem;
//             transition: color red;

//             span {
//                 margin-left: 0.5rem;
//             }

//             .menuitem-toggle-icon {
//                 margin-left: auto;
//             }

//             &:focus {
//                 @include focused-inset();
//             }

//             &:hover {
//                 background-color: blue;
//                 color:#fff;
//             }

//             &.router-link-exact-active {
//                 font-weight: 500;
//                 color: var(--gray-400);
//                 border:1px dotted var(--blue-200);
//             }

//         }

//         &.active-menuitem {
//             > a {
//                 .menuitem-toggle-icon {
//                     &:before {
//                         content: '\e933';
//                     }
//                 }
//             }
//         }

//         ul {
//             list-style-type: none;
//             margin: 0;
//             padding: 0;
//             overflow: hidden;

//             &.layout-submenu-wrapper-enter {
//                 max-height: 0;
//             }

//             &.layout-submenu-wrapper-enter-active {
//                 overflow: hidden;
//                 max-height: 1000px;
//                 transition: max-height 1s ease-in-out;
//             }

//             &.layout-submenu-wrapper-enter-done {
//                 transform: none;
//             }

//             &.layout-submenu-wrapper-exit {
//                 max-height: 1000px;
//             }

//             &.layout-submenu-wrapper-exit-active {
//                 overflow: hidden;
//                 max-height: 0;
//                 transition: max-height 0.45s cubic-bezier(0.86, 0, 0.07, 1);
//             }

//             ul {
//                 padding-left: 1rem;
//             }
//         }
//     }
// }
.layout-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 18rem;
    background: #1E1E1E;
    border-right: var(--sidebar-border);
    box-shadow: var(--sidebar-shadow);
    display: flex;
    flex-direction: column;
    z-index: 998;


    .sidebar-header {
        padding: 2.5rem 0 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .app-logo {
            .app-logo-normal {
                display: inline;
                width: 124px;
                height: 22px;
            }

            .app-logo-small {
                display: none;
            }
        }
    }


    .layout-menu-container {
        overflow: auto;
        flex: 1;
        padding-bottom: 2rem;
    }

    .layout-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .layout-root-menuitem {
            >.layout-menuitem-root-text {
                font-size: 12px;
                font-weight: 500;
                color: #7C7C7C;
                padding: 1rem 2.2rem;
            }

            >a {
                display: none;
            }
        }

        a {
            user-select: none;

            &.active-menuitem::after {
                >.layout-submenu-toggler {
                    transform: rotate(-180deg);
                }
            }
        }

        li.active-menuitem {
            >a {
                color: #7380FF;

                .layout-submenu-toggler {
                    transform: rotate(-180deg);
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
                display: flex;
                align-items: center;
                position: relative;
                outline: 0 none;
                color: #A1A5B7;
                cursor: pointer;
                padding: .75rem 2rem;
                // transition: background-color 0.2s, box-shadow 0.2s;

                .layout-menuitem-icon {
                    margin-right: 1rem;
                    width: 20px;
                    height: 20px;
                }


                .layout-submenu-toggler {
                    font-size: 75%;
                    margin-left: auto;
                    // transition: transform 0.2s;
                }

                &.active-route {
                    background-color: #252E37;
                    color: #7380FF;
                }

                &:hover {
                    background-color: #1429EF;
                    color: #fff;

                    .layout-menuitem-icon {
                        color: #fff;

                    }

                    .menu-icon {
                        filter: brightness(0) invert(1);
                        /* Change icon color on hover */
                    }
                }
            }

            ul {
                overflow: hidden;
                border-radius: var(--border-radius);

                li {
                    a {
                        padding-left: 4.5rem;
                        background: #252E37;

                        &.active-route {
                            color: #7380FF;
                            background: #252E37;
                        }
                    }

                    li {
                        a {
                            // padding-left: 4.5rem;
                            padding-left: 5rem;

                            &.active-route {
                                color: #7380FF;
                                background: #252E37;
                                //  background: #252E37;
                            }
                        }

                        li {
                            a {
                                padding-left: 3.5rem;

                                &.active-route {
                                    color: #7380FF;
                                    background: #252E37;
                                }
                            }

                            li {
                                a {
                                    padding-left: 4rem;

                                    &.active-route {
                                        color: #7380FF;
                                        background: #252E37;
                                    }
                                }

                                li {
                                    a {
                                        padding-left: 5.5rem;

                                        &.active-route {
                                            color: #7380FF;
                                            background: #252E37;
                                        }
                                    }

                                    li {
                                        a {
                                            padding-left: 5rem;

                                            &.active-route {
                                                color: #7380FF;
                                                background: #252E37;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.layout-sidebar,
.layout-menu-container,
.layout-content-wrapper {

  scrollbar-width: none; 
  scrollbar-color: gray transparent; 

  &::-webkit-scrollbar {
    width: 8px; 
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray; 
    border-radius: 10px; 
  }
}
