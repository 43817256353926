/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";
:root {
--saasyPrimaryColor: #23dc32;
--saasySecondaryColor: #1fc32c;
}
.class-input-error{
    border-color: red;
}

.p-chips .p-chips-multiple-container .p-chips-token{
    background-color: var(--primary-color);
}

#skip-warning{
    background-color: #fff2c0;
    outline: dashed;
    outline-width: thin;
    outline-color: #FFC700;
    border-radius: 10px;
    width: 100%;
}

#skip-icon{
    font-size: 2rem;
    background-color: #FFC700;
    color: white;
    border-radius: 60%;
    padding: 11px;
    transform: rotatex(180deg);
}

.conn-card {
    padding: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eff2f5;
    border-radius: .625rem;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}

// .conn-card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     min-width: 0;
//     word-wrap: break-word;
//     background-color: #fff;
//     background-clip: border-box;
//     border: 1px solid #eff2f5;
//     border-radius: .625rem;
//     box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
// }

.conn-card h1 {
    font-size:2rem;
    color:var(--gray-500);
}

.conn-grid-2x {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
}

.conn-grid-3x {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.conn-grid-4x {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4,1fr);
}

.n-action-button {
    color: #7E8299;
    background: var(--bluegray-100);
    border: 0 none;
    padding: 6px;
    // height: 3.37rem;
    font-size: 12px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-radius: 4px;
}

.invalid-feedback {
    color:red;
    margin-top:5px;
    font-size:0.9rem;
}

.label-with-input{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.signup-back-button .p-button{
    background: #def1fd;
    color: var(--brandColor);
}

.light-button {
    background: #def1fd;
    color: var(--brandColor);
}

.signup-subscription input[type="text"]{
    background-color: #eff2f5;
}

.ecommerce-option{
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 10em;
    padding: 1rem;
    border-radius: 5px;
}

.selected-eCommerce-plan  {
    background: var(--brandColor) !important;
    color: #fff !important;
}

.unselected-eCommerce-plan {
    background: #def1fd !important;
    color: var(--brandColor) !important;
}

.signup-hardware-quantity .p-inputnumber-input{
    width: 75px !important;
    border: 1px solid #d9d9d9 !important;
}

.signup-color{
    color: var(--brandColor);
}


.hd-cat-action-btns .p-button.p-button-icon-only {
    width: 8rem !important;
}

.device-description{
    color: #454545;
}

.device-price{
    color: #00457d;
}

.shipping-address-disabled{
    position: relative;
    cursor: not-allowed;
    pointer-events: none;
    background: #ffffff;
    opacity: 0.9;
}

.input-number-for-subscription {
    position: relative !important;
    background: #f5f8fa  !important;;
    z-index: 2  !important;;

    cursor: none  !important;;
    pointer-events: none  !important;;
    border: 1px solid #d9d9d9  !important;;
}

.input-number-for-subscription .p-inputnumber-buttons-stacked .p-inputnumber-button-group{
    right: -42px !important;;;
    cursor: pointer !important;;;
    pointer-events: all !important;;;
}

.p-dropdown{
    background: #eff2f5 !important;
}

.singup-step-wizard:after {
    content: "";
    position: absolute;
    z-index: 5;
    top: 87px;

    left: 10%;
    border-left: 2px dotted #8b8b8c;
}

.productviewsidebar .p-sidebar-full {
    width: 80vw;
    margin: auto auto;
    left: auto;
    height: 95vh;
    top: 11px;
}
.productviewsidebar .p-sidebar-full .shadow-3 {
    box-shadow: none !important;
    border:1px solid whitesmoke;

}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #898989;
}

.page-heading-color{
    color:var(--brandColor);
}

.disabled-input{
    cursor: not-allowed !important;
    background: #e0e0e0 !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    opacity: 0.8 !important;
} 

:host ::ng-deep .p-button.p-button-text:enabled:hover {
    background: var(--bs-primary);
    color: #ffffff;
    border-color: transparent;
}
//// Button hover Color
.p-button:enabled:hover{
    background: var(--brandColor) !important;
}
////Button Active Color
.p-button:enabled:active{
    background: var(--brandColor) !important;
}
.p-button.p-button-text:enabled:active, .p-button.p-button-outlined:enabled:active {
    background: rgba(63, 81, 181, 0.16) !important;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(33, 150, 243, 0.16) !important;
}
////Button Focus Color
.p-button:enabled:focus{
    background: var(--brandColor) !important;
    color: white !important;
}
.p-button:disabled.p-button-outlined {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.38) !important;
    border-color: rgba(0, 0, 0, 0.38) !important;
}
.p-button.p-button-info.p-button-outlined{
    color: var(--brandColor) ;
}
.p-button.p-button-info.p-button-outlined:enabled:hover{
    color: white;
}

//// Icon Button
.p-button.p-button-text:enabled:hover {
    background: var(--bs-light) !important;
    color: var(--bs-primary) !important;
    border-color: transparent !important;
}
//// Export To Excel
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(104, 159, 56, 0.04) !important;
    color: #689F38 !important;
    border: 0 none !important;
}
//// Export To PDF
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent !important;
    color: #FBC02D !important;
    border: 0 none !important;
}
//// Sign Out Button Color
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(211, 47, 47, 0.04) !important;
    border-color: transparent !important;
    color: #D32F2F !important;
}
//// Panelmenu Color
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: var(--brandColor) !important;
}
//// Tabmenu Color
.p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: var(--brandColor) !important;
}
////p-Slider Color
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    // background: #edf1f5;
    background: var(--brandColor) !important;;
  }
//// p-Slider Hover Color
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: var(--brandColor);
  }
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(211, 47, 47, 0.04) !important;
    color: #D32F2F !important;
    border: 0 none !important;
}
.button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(211, 47, 47, 0.16) !important;
}
// Danger Button
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: rgba(211, 47, 47, 0.92) !important;
}
// // P-input Background
.p-inputtext {
    background-color:#eef0f9 !important;
}
.p-inputtext:enabled:focus {
    background: #E4E6EF !important;
}
.tty{
    background-color: #eef0f9 !important; 
}
//  button.p-ripple.p-element.p-confirm-dialog-accept.p-button.p-component {
//     background-color: red !important;
// }
//  button.p-ripple.p-element.p-confirm-dialog-reject.p-button.p-component {
//     color: black !important;
//     background: #dddedf !important;
// }
//  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
//     color: red !important;
//   }
.dashboard-center-free-trial{
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    background: #000;
}
.plan-btn-upgrade{
    background: var(--brandColor);
    padding: 7px 20px;
    border-radius: 5px;
    margin: 0px 10px;
}
.blueColor{
    color: #1429EF !important;
}
.full-tour{
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 999;
}
.main-tour{
        background: #00000080;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 997;
}
